
import moment from 'moment-timezone'
export default {
  cleanDocument: document => document.replace(/[^0-9]/g, ""),
  cleanMobilePhone: mobilePhone => mobilePhone.replace(/[^0-9\+]/g, ""),
  cleanCurrency: document => document.replace(/[^,0-9]/g, "").replace(",", "."),
  cleanCurrencyAll: document => document.replace(/[^,0-9\-]/g, "").replace(",", "."),
  mobileFormat: mobilePhone => mobilePhone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
  personDocumentFormat: personDocument =>
    personDocument.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
  documentFormat: document =>
    document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
  documentFormatHidden: document =>
    document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '**.$2.$3/$4-**'),
  phoneFormat: phone => phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3"),
  billetsFormat: billet =>
    billet.replace(
      /(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/,
      "$1.$2 $3.$4 $5.$6 $7 $8"
    ),
  emailValidator: email =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
      email
    ),

  maskToDocument(item) {
    let document = "";
    const indexItem = item.split("");
    indexItem.map((element, index) => {
      switch (index) {
        case 1:
        case 4:
          document += `${element}.`;
          break;
        case 7:
          document += `${element}/`;
          break;
        case 11:
          document += `${element}-`;
          break;
        default:
          document += element;
      }
      return true;
    });
    return document;
  },
  formatThousands: value => new Intl.NumberFormat("pt-BR").format(parseFloat(value)),
  formatCurrency: value =>
    new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
      parseFloat(value)
    ),
  dataSort: (data, index = 'date', desc = false) => {
    return data.sort((a, b) => {
      if(desc) {
        return moment(b[index]).isBefore(moment(a[index]))
         ? 1
         : -1;
      }
      return moment(a[index]).isBefore(moment(b[index]))
       ? 1
       : -1;
    })
  },
  customSort(data, index, isDesc) {
    const keyIndex = `"${index[0]}"`.toLowerCase();
    const dateExists = keyIndex.includes("date");
    data.sort((a, b) => {
      if (dateExists) {
        if (!isDesc[0]) {
          return this.$moment(b[index], "DD/MM/YYYY").isAfter(this.$moment(a[index], "DD/MM/YYYY"))
            ? 1
            : -1;
        }
        return this.$moment(a[index], "DD/MM/YYYY").isBefore(this.$moment(b[index], "DD/MM/YYYY"))
          ? -1
          : 1;
      }

      if (typeof a[index] !== "undefined") {
        if (!isDesc[0]) {
          return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
        }
        return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
      }
    });
    return data;
  },
  sortDates(dates){
    const sortedDates = dates.slice()
    if(moment(dates[1]) < moment(dates[0])){
      const aux = sortedDates[1]
      sortedDates[1] = sortedDates[0]
      sortedDates[0] = aux
    }  
    return sortedDates
  },
};
