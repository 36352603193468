<template>
  <div class="background">
    <div class="container">
        <Logo />
        <v-alert class="alertCreated" dense outlined v-if="createdLogin.created">
            Cadastro criado com sucesso, aguarde contato do Comercial.
        </v-alert>
        <v-alert class="alert" dense outlined v-if="error">{{error}}</v-alert>
        <v-card class="py-md-8 px-md-12 py-4 px-6" outlined>
          <slot></slot>
        </v-card>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Logo from '@/components/Logo.vue';
  export default {
    components: { Logo },
    computed: {
      ...mapGetters({
        error: 'Auth/getError',
      }),
      createdLogin() {
        return this.$store.state.Auth.messages;
      }
    }
  }
  
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/login/login.scss';
</style>
